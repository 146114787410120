<script lang="ts" setup>
import AButton from "@netgame/ui/atoms/AButton.vue";
import AAnimationSlide from "@netgame/ui/atoms/animations/AAnimationSlide.vue";
import MDropdown from "@netgame/ui/molecules/MDropdown.vue";

const { t } = useT();
const { open } = useNlcModals();
const isGuest = useIsGuest();
const loginGuard = useLoginGuard();

const {
	feedback,
	subject,
	feedbackMsg,
	maxLength,
	minLength,
	isSubjectTouched,
	isFeedbackMsgTouched,
	isFeedbackMsgValid,
	isLimitedValid,
	verifyLoading,
	categories,
	dataSettings,
	isSubjectShowError,
	isFeedbackMsgShowError,
	numberSymbols,
	handleSendFeedbackClick,
	isFormSent
} = useFeedbackForm();
const { handleSupportButtonClick } = useSupportChat();

const handleClick = () => {
	loginGuard({
		success: () => {
			handleSendFeedbackClick();
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<AAnimationSlide
		v-if="dataSettings?.statusCode !== 403"
		mode="left-to-right"
		iterationCount="3"
		:duration="0.2"
		:distance="5"
		:active="!isLimitedValid"
		:class="['feedback', { 'feedback-guest': isGuest }]"
	>
		<div ref="feedback" class="head">
			<AText class="text-cannes" :modifiers="['uppercase', 'bold', 'center']">
				<NuxtImg
					class="icon"
					src="/nuxt-img/footer/feedback.png"
					alt="feedback"
					width="17"
					height="17"
					loading="lazy"
					format="webp"
				/>
				{{ dataSettings?.data?.title }}
			</AText>

			<AText v-if="isLimitedValid" class="text-caracas" :modifiers="['center']">
				{{ dataSettings?.data?.description }}
			</AText>
			<AText v-else class="invalid" :modifiers="['center']">
				<i18n-t keypath="The response limit has been exceeded, please try tomorrow or contact {key}" tag="div">
					<template #key>
						<AText :modifiers="['link', 'underline']" class="link" @click="handleSupportButtonClick">
							{{ t("our support team.") }}
						</AText>
					</template>
				</i18n-t>
			</AText>
		</div>
		<div v-if="isFormSent" class="form-sent" data-tid="feedback-sent">
			<NuxtImg
				class="icon"
				src="/nuxt-img/footer/feedback-success.png"
				loading="lazy"
				alt="feedback-success-sent"
				width="71"
				height="92"
				format="webp"
			/>
			<AText variant="toledo" :modifiers="['bold']" data-tid="feedback-sent-txt">{{ t("Feedback sent") }}</AText>
			<AText class="text-caracas" :modifiers="['center']">{{
				t("Thank you for helping us become better. Take care and game on with NoLimitCoins")
			}}</AText>
		</div>
		<form v-else class="form" @submit.prevent="handleClick">
			<div v-if="categories?.length" class="row-select">
				<MDropdown
					v-model="subject"
					:placeholder="t('Select a feedback subject')"
					:class="['select', { invalid: isSubjectShowError }]"
					data-tid="feedback-dropdown"
					savePlaceholder
					@blur="isSubjectTouched = true"
				>
					<template #beforeInput>
						<NuxtIcon v-if="subject" name="20/feedback2" class="icon" filled />
					</template>
					<template #content="{ setValue }">
						<AOption
							v-for="(item, index) in categories"
							:key="index"
							:data-tid="`option-${item.name}`"
							@click="setValue(item.name)"
						>
							{{ item.name }}
						</AOption>
					</template>
				</MDropdown>
				<AAnimationSlide :active="!!isSubjectShowError" :class="{ hide: !isSubjectShowError }">
					<AText variant="topeka" class="invalid">{{ t("The feedback subject is not selected") }}</AText>
				</AAnimationSlide>
			</div>

			<label class="row-textarea">
				<div class="relative">
					<MTextarea
						v-model="feedbackMsg"
						:placeholder="t('Enter the text (min') + ` ${minLength} ` + t('characters)')"
						autocomplete="feedbackMsg"
						:maxlength="maxLength"
						location-tid="feedback"
						clear
						:invalid="isFeedbackMsgShowError"
						class="message"
						@blur="isFeedbackMsgTouched = true"
					/>
					<AText variant="tivat" class="number-symbol" :data-txt="numberSymbols" />
				</div>
				<AAnimationSlide :active="!!isFeedbackMsgShowError" :class="{ hide: !isFeedbackMsgShowError }">
					<AText variant="topeka" class="invalid">
						<i18n-t keypath="Enter the text (min {key} characters)">
							<template #key>
								{{ minLength }}
							</template>
						</i18n-t>
					</AText>
				</AAnimationSlide>
			</label>
			<AButton
				type="submit"
				class="button"
				variant="primary"
				data-tid="feedback-submit-btn"
				:modifiers="[!isFeedbackMsgValid && !isGuest ? 'disabled' : '']"
			>
				<AAnimationSpin v-if="verifyLoading">
					<NuxtIcon class="preloader-icon" name="24/loader" filled />
				</AAnimationSpin>
				<AText v-else :modifiers="['medium', 'uppercase']">
					{{ isGuest ? t("Sign Up or Login") : t("Send message") }}
				</AText>
			</AButton>
		</form>
	</AAnimationSlide>
</template>

<style scoped lang="scss">
.feedback {
	display: flex;
	width: 328px;
	background: var(--chaozhou);
	padding: gutter(2);
	flex-direction: column;
	border-radius: 8px;
	gap: gutter(2);

	@include media-breakpoint-down(lg) {
		width: 100%;
		max-width: 500px;
		margin-bottom: gutter(3);

		@media screen and (orientation: landscape) {
			margin: 0 auto gutter(3);
		}
	}

	.head {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: gutter(0.5);

		.icon {
			display: inline-flex;
			margin: gutter(-0.5) gutter(0.25) 0 0;
			transform: translateY(4px);
		}
	}

	.invalid {
		color: var(--celaya);
	}

	.hide {
		visibility: hidden;
	}

	.form {
		display: flex;
		flex-direction: column;
		gap: gutter(0.5);

		.label {
			margin-bottom: gutter(0.5);
		}

		.message {
			height: 80px;
			padding: gutter(1.25);
			@include scrollbar(4px, 4px, transparent, var(--cocoi), transparent);
		}

		.button,
		.button.disabled:hover {
			margin-top: gutter(0.25);
			background: var(--changchun);
			border-color: var(--changchun);
			height: 36px;

			.preloader-icon {
				font-size: 24px;
			}
		}
	}
	.form-sent {
		display: flex;
		gap: gutter(2);
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
}

.row-select:deep(.select) {
	display: flex;

	& > div {
		width: 100%;
		margin: 0px !important;
		border: none !important;
	}

	.dropdown {
		box-shadow: none;
		background: var(--chers);

		input {
			color: var(--cannes);
		}
	}

	&.invalid .dropdown {
		box-shadow: 0 0 1px 1px var(--celaya);
	}

	.popper,
	.option {
		border: none;
		background: var(--chers);
		color: var(--cannes);
	}

	.popper {
		padding: gutter(0.5) 0;
		@include scrollbar(4px, 4px, transparent, var(--cocoi), transparent);
	}
	.option:hover {
		background: var(--chaozhou);
		border-radius: 0;
	}

	.placeholder {
		padding-left: 0;

		&.moved {
			padding-left: gutter(1.25);
			transform: translate(16px, -10px);
		}
	}
}

.feedback-guest {
	.row-select,
	.row-textarea {
		pointer-events: none;
		opacity: 0.4;
	}
}

.relative {
	position: relative;
}

.number-symbol {
	color: var(--a-input-placeholder-color);
	position: absolute;
	bottom: -6px;
	left: 10px;
	padding: 0 gutter(0.75);

	&:before {
		content: "";
		display: block;
		height: 2px;
		width: 100%;
		background: var(--chers);
		position: absolute;
		left: 0;
		bottom: 6px;
	}

	&:after {
		content: attr(data-txt);
		position: relative;
		z-index: 1;
	}
}
</style>
